import en from 'vuetify/lib/locale/en';
import { Enums } from '@flightscope/baseball-stats';
import forms from './forms';
import views from './views';

const supportMail = process.env.VUE_APP_SUPPORT_MAIL;
const mailLinkUnderlined = `<a class="black--text" href="mailto:${supportMail}">${supportMail}</a>`;
const mailLink = `<a class="black--text text-decoration-none" href="mailto:${supportMail}">${supportMail}</a>`;
const supportPhone = process.env.VUE_APP_SUPPORT_PHONE;
const supportPhoneNoSpace = supportPhone.replaceAll(/[ -]/g, '');

export default {
  ...en,
  alerts: {
    invalid_password: 'Password you entered is invalid.',
    invalid_credentials: 'Invalid login or password.',
    forbidden_access: 'Forbidden access, please log in or switch account.',
    user_not_verified: 'Please verify account.',
    no_response: 'No response, please try again later.',
    login_required: 'Login is required.',
    login_not_valid: 'Login must be valid email.',
    password_required: 'Password is required.',
    password_minlength: 'Passwords must be at least 6 characters long.',
    confirmPassword_required: 'Password confirmation is required.',
    confirmPassword_notMatch: 'Please make sure your passwords match',
    email_required: 'Email is required.',
    email_not_valid: 'Email must be valid.',
    email_in_use: 'Email is in use.',
    firstName_required: 'First name is required.',
    lastName_required: 'Last name is required.',
    consent_required: 'Consent is required.',
    request_error: `There was an error. Please send request again or refresh page.
      If you are still experiencing any problems, contact support at
      ${mailLinkUnderlined}`,
    verification_in_progress: 'Verifying request',
    request_denied: "You've denied the request. Thank you for the information.",
    link_expired: 'This link expired.',
    user_verification_pos: 'Your user verification was accepted.',
    user_verification_neg: 'Your user verification was rejected.',
    response_not_readable: 'Request went well, but response could not be read.',
  },

  forms,
  views,

  playersFilter: {
    baseTeamText: 'All Teams',
    basePositionText: 'All positions',
    clipboardSnackbar: {
      successText: 'Page URL was copied to clipboard.',
      errorText: 'Page URL: {0}',
    },
    clipboardTooltipText: 'Copy URL',
    clipboardTooltipCloseActionText: 'Close',
  },
  playerFilters: {
    baseTeamText: 'All Teams',
    chartTypeLabel: 'Report type',
    battingSelectHeader: 'Batting reports',
    pitchingSelectHeader: 'Pitching reports',
    seasonLabel: 'Season',
    seasonFromLabel: 'Season From',
    seasonToLabel: 'Season To',
    teamLabel: 'Teams',
    batHandednessLabel: 'Batter Handedness',
    throwHandednessLabel: 'Pitcher Handedness',
    datesRangeLabel: 'Date Range',
    today: 'Today',
    yesterday: 'Yesterday',
    last7Days: 'Last 7 Days',
    last30Days: 'Last 30 Days',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    customRange: 'Custom Range',
    gameTypeLabel: 'Game type',
    gameTypePractice: 'Practice',
    gameTypeGame: 'Game',
    gameTypeGameVerified: 'Verified Game',
    gameTypeAll: 'All',
    allIn: 'All in',
  },
  pitchResultTypes: {
    SS: 'Swinging Strike',
    CS: 'Called Strike',
    SE: 'Single',
    DE: 'Double',
    TE: 'Triple',
    HR: 'Home Run',
    B: 'Ball',
    IB: 'Intentional Ball',
    FB: 'Foul Ball',
    HP: 'Hit by Pitch',
    E: 'Error',
    CO: 'Contact Out',
    BB: 'Walk',
    SSO: 'Swinging Strikeout',
    CSO: 'Called Strikeout',
    UI: 'Unidentified',
    undefined: 'Unidentified',
  },
  pitchTypes: {
    FA: 'Fastball',
    CH: 'Changeup',
    SL: 'Slider',
    CU: 'Curveball',
    SI: 'Sinker',
    SP: 'Splitter',
    FC: 'Cutter',
    KN: 'Knuckleball',
    OT: 'Other',
    UI: 'Unidentified',
    FF: 'Four-seam fastball',
    FT: 'Two-seam fastball',
    FK: 'Forkball',
    SV: 'Slurve',
    SC: 'Screwball',
    CC: 'Circle Changeup',
    KC: 'Knuckle Curve',
    EP: 'Eephus',
  },
  handedness: {
    L: 'Left',
    R: 'Right',
    S: 'Switch',
    UI: 'Unidentified',
  },
  sanitizer: {
    headers: {
      sanitization: 'Sanitization Result',
      validation: 'Validation Reason',
    },
  },
  playerProfile: {
    pitchingReleasePointChart: {
      title: 'Release Point',
    },
  },
  tooltip: {
    pointData: 'Point Data',
    pitcherHand: 'Pitcher Hand',
    pitchSpeed: Enums.PitchData.Speed.short,
    pitchSpin: Enums.PitchData.Spin.short,
    strikeZoneSector: 'Strike Zone Sector',
    pitchCount: Enums.PitchData.BeforePlayPitchCount.short,
    pitchResult: 'Pitch Result',
    pitchType: 'Pitch Type',
    battedBallType: 'Batted Ball Type',
    batterHand: 'Batter Hand',
    hitDistance: Enums.HitData.CarryDistance.short,
    hitExitSpeed: 'Hit Exit Speed',
    hitLaunchV: 'Hit Launch V',
    plateAppearance: 'Plate Appearance',
    pitcher: 'Pitcher',
    batter: 'Batter',
    scoringData: 'Scoring Data',
    seeDetailsTable: 'Table',
    videoDialog: 'Videos',
    pitchCall: 'Pitch Call',
    plateAppearanceResult: 'Plate Appearance Result',
  },
  battedBallTypes: {
    [Enums.BattedBallType.GroundBall.key]: 'Ground ball',
    [Enums.BattedBallType.LineDrive.key]: 'Line drive',
    [Enums.BattedBallType.PopUp.key]: 'Pop up',
    [Enums.BattedBallType.FlyBall.key]: 'Fly ball',
    [Enums.BattedBallType.Unidentified.key]: 'Unidentified',
  },
  [Enums.PitchData.BeforePlayInning.key]: Enums.PitchData.BeforePlayInning.short,
  [Enums.PitchData.BeforePlayBalls.key]: Enums.PitchData.BeforePlayBalls.short,
  [Enums.PitchData.BeforePlayStrikes.key]: Enums.PitchData.BeforePlayStrikes.short,
  [Enums.PitchData.BeforePlayRunnersOn.key]: Enums.PitchData.BeforePlayRunnersOn.short,
  [Enums.PitchData.BeforePlayOuts.key]: Enums.PitchData.BeforePlayOuts.short,

  reports: {
    releasePointChart: 'Release Point Chart',
    releaseExtensionChart: 'Release Extension Chart',
    pitchMovementChart: 'Pitch Movement Chart',
    pitchLocationChart: 'Pitch Location Chart',
    strikeZoneChart: 'Strike Zone Chart',
    sprayChart: 'Spray Chart',
    pitcherPerformanceTracking: 'Pitcher Performance Tracking',
    batterPerformanceTracking: 'Batter Performance Tracking',
    pitchingVideos: 'Pitching videos',
    battingVideos: 'Batting videos',
    postGame: 'Post Game',
    battedBallQualityChart: 'Batted Ball Quality',
    contactReportChart: 'Contact Report',
  },

  releaseSide: 'Release side',
  releaseHeight: 'Release Height',
  releaseExtension: 'Release Extension',
  pitchLocationChart: 'Location Chart',
  horizontalLocation: 'Horizontal location',
  verticalLocation: 'Vertical location',
  releaseExtensionChart: 'Extension Chart',
  horizontalMovement: 'Horizontal movement',
  verticalMovement: 'Vertical movement',
  landingHorizontal: 'Landing - Horizontal',
  landingVertical: 'Landing - Vertical',
  balls: 'Balls',
  strikes: 'Strikes',
  Performance: 'Performance',
  Repeatability: 'Repeatability',
  PlateDiscipline: 'Plate Discipline',
  charts: {
    heatmaps: {
      releasePoint: 'Release Point Heat Map',
      releaseExtension: 'Release Extension Heat Map',
      pitchMovement: 'Pitch Movement Heat Map',
      pitchLocation: 'Pitch Location Heat Map',
      strikeZone: 'Strike Zone Heat Map',
    },
  },
  sessionTypes: {
    PitchingSession: 'Bullpen',
    BattingSession: 'Batting Practice',
    Game: 'Game',
    GamePractice: 'Scrimmage',
    LiveBP: 'Live BP'
  },
  login: 'Login',
  continue: 'Continue',
  logIn: 'Log in',
  password: 'Password',
  forgotPassword: 'Forgot password?',
  selectThePlanType: 'Select your plan type',
  price: 'Price',
  free: 'free',
  month: 'month',
  year: 'year',
  or: 'or',
  save: 'save',

  registerForm: {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Your email address',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    submitForm: 'Create account',
    heading: 'Create An Account',
    alreadyAMember: 'Already a member?',
    formSentText: 'Thank you for registering. Please check your inbox for further information.',
    consent: `I have read and accept`,
    registerSuccessActionText: 'Home page',
    logIn: 'Log in',
    termsOfUse: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
    and: 'and',
    tosHeader: 'Read complete FlightScope Terms and Conditions',
  },
  signIn: 'Sign In',
  servicePlan: {
    choosePlanText: 'Choose this plan',
    annualPayment: 'Annual payment',
    monthlyPayment: 'Monthly payment',
    year: 'year',
    month: 'month',
    freeAccountPlanText: 'Free Account Plan',

    free: 'free',
    includingAccessTo: 'Including access to:',
    monthlyPrice: '${0} / month',
    annualPrice: 'or ${0} / annual',
    choosePlan: 'Choose this plan',
    confirmChoice: 'CONFIRM your choice',
  },
  planSelector: {
    heading: 'Subscription plan',
    dialogHeading:
      'To get access to FS Cloud {0} Web Portal<br>Choose one from the <b>FS Cloud Subscription Plans</b> below',
  },
  startPasswordRecoveryForm: {
    heading: 'Reset password',
    emailLabel: 'Email',
    emailPlaceholder: 'Enter your email address',
    submitForm: 'Recover password',
    formSentText: `If the user exists in our system,
      an email with further instructions will be send to email address you provided.
    If you don't receive a message in 5 minutes, check the junk folder. If you are still experiencing any problems,
    contact support at
    ${mailLink}`,
    formSendingErrorText: `There was an error during sending reset password instructions.<br>
    If you are still experiencing any problems, refresh page or contact support at
    ${mailLink}`,
    recoverAnotherEmail: 'Reset again',
    rememberPassword: 'Remember password?',
  },
  resetPasswordForm: {
    heading: 'Reset password',
    rememberPassword: 'Remember password?',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    submitForm: 'Save',
    formSentText: `Your password is changed. Now you can
    <a class="black--text text-decoration-none" href="/account/login">Sign In</a> with new credtentials.`,
    formSendingErrorText: `There was an error during changing your password.<br>
    Please, <a class="black--text text-decoration-none" href="/account/password-recovery">recover it again</a>
    or contact support at
    ${mailLink}`,
    recoverAgain: 'Reset again',
  },
  loginForm: {
    emailPlaceholder: 'Your email address',
    createAccount: 'Create an account',
    newUserText: 'New User?',
    forgotPasswordText: 'Forgot your password?',
  },
  landingView: {
    bannerTitle: 'The World\'s Most Advanced<br> <span>{0}</span>&nbsp;Data Cloud',
    bannerSubtitle: 'Collect, analyze, and compare data from <span>{0}</span>&nbsp; <br class="d-none d-md-block" />games and training sessions',
    bannerCaption: 'Performance Data You Can Trust',
    ctaText: 'Check out our plans',
    contactData: `Contact Us: <br class="d-sm-none" />
    <a class="white--text" href="mailto:mailto:${supportMail}">${supportMail}</a>
    <br class="d-sm-none" />
    <span class="d-none d-sm-inline-block mx-2">|</span>
    <a class="text-decoration-none white--text" href="tel:${supportPhoneNoSpace}">${supportPhone}</a>`,
    plansCaption: `Whether you are a player, coach or team, look into three plans that FlightScope Cloud has to offer.<br>
    They include a variety of valuable features, so choose the one that best fits your needs.`
  },
  linkHandlerModule: {
    redirect_info: 'You will be redirected automatically in:',
    redirect_info_or: 'Or you can go directly',
    btn_go_back: 'Go back',
    btn_goto_login: 'Go to Home Page',
  },
  userProfile: {
    profileHeading: 'Your profile',
    submitText: 'Update profile information',
    successMessage: 'Your profile data was updated successfully.',
    passwordSuccess: 'Your password was updated successfully.',
    manageMembershipHeading: 'Manage your membership',
    editProfileHeading: 'Edit profile',
    cancelSubscriptionSuccessMessage: `Your subscription cancel request was sent.
      Changes will be applied at the beginning of next billing period.`,
    cancelSubscriptionErrorMessage: `There was an error. Please send request again or refresh page.
    If you are still experiencing any problems, contact support at
    ${mailLinkUnderlined}`,
    cancelMembershipTitle: 'Membership cancellation',
    cancelMembershipText: 'This action change will take effect at the end of billing period.',
    upgradeMembershipSuccessMessage: `Your subscription plan update request was sent.
      Changes will be applied soon.`,
    upgradeMembershipErrorMessage: `There was an error. Please send request again or refresh page.
      If you are still experiencing any problems, contact support at
      ${mailLinkUnderlined}`,
    downgradeMembershipSuccessMessage: `Your subscription plan update request was sent.
      Changes will be applied at the beginning of next billing period.`,
    downgradeMembershipErrorMessage: `There was an error. Please send request again or refresh page.
      If you are still experiencing any problems, contact support at
      ${mailLinkUnderlined}`,
    upgradeMembershipTitle: 'Upgrade membership',
    upgradeMembershipText: 'Changes will be applied as soon as possible.',
    downgradeMembershipTitle: 'Downgrade membership',
    downgradeMembershipText: 'Changes will be applied at the beginning of next billing period.',
    changeTo: 'Change to',
    cancelSubscription: 'Cancel subscription',
    newMembershipSuccessMessage: `Your transaction was billed successfully.
        Changes will be applied soon.`,
    planDetails: {
      planWillChange: 'Your plan will change on {0} to {1}',
      titlePlaceholder: 'Plan name',
      planWillCharge: 'Your plan will be charged on {0}',
    },
  },
  firstNameLabel: 'First Name',
  lastNameLabel: 'Last Name',
  displayNameLabel: 'Display Name',
  emailLabel: 'Email',
  discount: 'discount',
  chargeDate: 'Charge date',
  okText: 'ok',
  cancelText: 'cancel',
  nextLevel: 'Will change to',
  expiresOn: 'Expires on',
  on: 'on',
  paymentForm: {
    fields: {
      country: 'Country',
      address: 'Address',
      state: 'State',
      city: 'City',
      postal_code: 'ZIP',
      name: 'Name on card',
      email: 'Receipt email',
    },
    notif: {
      sendingRequest: 'Sending payment request...',
      error_request: `There was an error. Please refresh the page, fill the form and submit again.
      If you are still experiencing any problems, contact support at
      ${mailLinkUnderlined}`,
    },
  },
  accountsForm: {
    notif: {
      subTitle: 'Sign in or Create an Account',
    },
  },
  landingHeader: {
    createAccount: 'Create account',
  },
  footer: {
    regulationsLine1: `Copyright © {0} - {1} FlightScope (Pty) Ltd. All rights reserved.`,
    regulationsLine2: `Apple, the Apple logo, iPod and iPod touch are trademarks of Apple Inc., registered in the U.S.
    and other countries.<br class="d-none d-lg-block" /> iPhone is a trademark of Apple Inc. App Store is a service mark of Apple Inc. Android is
    a trademark of Google Inc. Use of this trademark is subject to Google Permissions.`,
  },
  tosDialog: {
    heading: 'Dear user,',
    subheading: 'If you wish to continue using our services, you must agree to FlightScope Terms and Conditions defined below:',
    openInNewWindow: 'Open in new window',
  },
  careerStats: {
    heading: 'Career Stats',
    headingAdvanced: 'Advanced Career Stats',
  },

  account: {
    logged_in: 'Logged-in Account',
    custom_type: 'Custom Account',
  },

  tableCell: {
    clipboardSnackbar: {
      successText: 'Value was copied to clipboard.',
    },
    clipboardTooltipText: 'Click to copy',
    clipboardTooltipCloseActionText: 'Close',
  }
};
