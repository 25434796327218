import get from 'lodash/get';
import coerce from 'semver/functions/coerce';
import gte from 'semver/functions/gte';
import Vue from 'vue';
import { Model } from '@vuex-orm/core';
import {
  Lineup,
  Media,
  Result,
  FormattedResult,
  Player,
} from '@/models/orm/Hierarchy';
import { ELineupType } from '@/enums/dto';
import { SessionType } from '@/store/actions/api';

import { Providers, Entities, Enums, Classificators } from '@flightscope/baseball-stats';
import { ResultType } from '@flightscope/baseball-stats/src/enums';

import sessionResultsFormatter from '@/formatters/sessionResultsFormatter';
import FilterStores from '@/enums/StoreKeys';
const scoringSessionTypes = [SessionType.GAMES,SessionType.PRACTICE];

// https://bitbucket.org/flightscope/dto-schema/src/b0a7e7f7c0a3e307e750a4bd2cac8da0706f8109/baseball/entity/session-v2.json?at=feature/baseball
export class Session extends Model {
  // CASL
  static get modelName() {
    return 'Session';
  }

  // This is the name used as module name of the Vuex Store.
  static entity = 'sessions';

  static state() {
    return {
      fetching: false,
    };
  }

  static fields() {
    return {
      id: this.number(null),
      access: this.attr(''),
      createDate: this.string(''),
      dataVerified: this.boolean(false),
      deviceID: this.string('').nullable(),
      displayName: this.string(''),
      endDate: this.string('').nullable(),
      gameStatus: this.string(''),
      lineScore: this.attr(null).nullable(),
      lineupTeamsOrganizationCodes: this.attr(null).nullable(),
      lineups: this.hasMany(Lineup, 'sessionID'),

      mediaForResultIds: this.attr(null).nullable(),
      mediaForSessionIds: this.attr(null).nullable(),

      mediaForResult: this.hasManyBy(Media,'mediaForResultIds'),
      mediaForSession: this.hasManyBy(Media, 'mediaForSessionIds'),

      modificationDate: this.string(''),
      ownerID: this.number(null),
      parameters: this.attr(null),
      processingDate: this.string('').nullable(),

      results: this.hasMany(Result, 'sessionID'),
      resultsv1: this.hasMany(FormattedResult, 'SessionID'),

      rules: this.attr(null),
      sessionType: this.string(''),
      sessionTypeID: this.number(null),
      status: this.string(''),
      isOfficial: this.boolean(false),
      // non-dto fields
      filters: this.attr(null).nullable(),
      // localCache
      ttlExpire: this.number(null).nullable(),
    };
  }

  /**
   *
   * @param {Session} model
   */
  static beforeCreate(model) {
    model.filters = Object.values(FilterStores).reduce((ac, a) => ({ ...ac, [a]: {} }), {});
  }

  /** @type {null|Lineup} */
  get homeLineup() {
    let lineup = this.lineups.find((l) => l.type === ELineupType.HOME);
    if (!lineup) {
      lineup =this.lineups.find(l => l.type === ELineupType.PRACTICE);
    }
    return lineup;
  }

  /** @type {null|Lineup} */
  get awayLineup() {
    return this.lineups.find((l) => l.type === ELineupType.AWAY);
  }

  /** @type {String} */
  get schema() {
    return this?.parameters.SessionSchemaRef ? (this?.parameters.SessionSchema || '2.0.0') : (this?.parameters.SessionSchema || '0');
  }

  /** @type {String} */
  get type() {
    return this.sessionType ?? '_default_';
  }

  get sessionContents() {
    return this?.parameters?.SessionContents ?? '';
  }

  get features() {
    const feats = {
      R: false,
      T: false,
      S: false,
    };
    switch (this.schema) {
      case '2.0.0':
        this.sessionContents.split('').forEach((v) => {
          if (feats.hasOwnProperty(v)) feats[v] = true;
        });
        break;
      default:
        feats.R = true;
        feats.T = true;
        feats.S = gte(coerce(this.schema), '1.7.0');
    }
    return feats;
  }

  get v2() {
    return this.schemaVer === 2;
  }

  get schemaVer() {
    if (gte(coerce(this.schema), '2.0.0')) {
      return 2;
    }
    return 1;
  }

  get mightHaveTagging() {
    return this.features.T;
  }

  get mightHaveScoring() {
    return scoringSessionTypes.includes(this.type) && this.features.S;
  }

  get hasNoPitchingData() {
    return this.type === SessionType.HITTING;
  }

  get hasNoHittingData() {
    return this.type === SessionType.PITCHING;
  }

  get homeTeamLoaded() {
    return this.homeLineup && !!this.homeLineup.team;
  }

  /** @type {Player[]} */
  get players() {
    let out = [];

    if (this.awayLineup?.team?.players) {
      out = this.awayLineup.team.players
    }

    if (this.homeLineup?.team?.players) {
      out = [...out, ...this.homeLineup.team.players];
    }

    return out;
  }

  /** @type {Player[]} */
  get batters() {
    const people = Providers.PlayerListProvider.getListOfBatters(this.resultsv1);

    const mapped = people.map((player) => {
      const found = this.players.find((p) => p.id === player.id);

      if (found) return found;

      // throw new Error('TODO: Implement this');

      return { ...player, type: 'unknown' };
    });

    return mapped;
  }

  /** @type {Player[]} */
  get pitchers() {
    const people = Providers.PlayerListProvider.getListOfPitchers(this.resultsv1);

    const mapped = people.map((player) => {
      const found = this.players.find((p) => p.id === player.id);

      if (found) return found;

      // throw new Error('TODO: Implement this');

      return { ...player, type: 'unknown' };
    });

    return mapped;
  }

  /**
   *
   * @param {Number} id
   * @returns {(Player|null)}
   */
  getPlayer(id) {
    let player = this.players.find((p) => p.id === id);

    if (player) {
      return player;
    } else {
      Vue.$log.debug(`Could not find player ${id}`);
      return null;
    }
  }

  /**
   *
   * @param {Number} id
   * @returns
   */
  mapMediaForResult(id) {
    let media = this.mediaForResult.find(m => m.id === id);

    if (media) return media;
    return new Media({id});
  }

  /**
   *
   * @param {FormattedResult} [hitResult]
   * @param {FormattedResult} [pitchResult]
   * @returns {(Player|null)}
   */
  getBatter(hitResult, pitchResult) {
    if (hitResult) {
      return this.getPlayer(hitResult[Enums.ResultData.TagId.key]);
    }

    if (pitchResult) {
      return this.getPlayer(pitchResult[Enums.ResultData.BatterTagId.key]);
    }

    return null;
  }

  /**
   *
   * @param {FormattedResult} [pitchResult]
   * @returns {(Player|null)}
   */
  getPitcher(pitchResult) {
    if (pitchResult) {
      return this.getPlayer(pitchResult[Enums.ResultData.TagId.key]);
    }

    return null;
  }

  get MatchingResult() {
    return /** @param {Number} id @return {FormattedResult|null}*/ id => {
      return this.resultsv1.find((r) => r.ResultID === id) || null;
    };
  }

  /**
   *
   * @param {FormattedResult} [matchingResult]
   * @returns
   */
  static isHitType(result) {
    if (!result) {
      return false;
    }

    return result.ResultType && result.ResultType === ResultType.Hit.key;
  }

  /**
   *
   * @param {Number} [id]
   * @returns {(FormattedResult|null)}
   */
  getPitchForMatchingResult(id) {
    let result = this.MatchingResult(id);

    if (!result) {
      return null;
    }

    if (Session.isHitType(result)) {
      const pitchId = result[Enums.ResultData.PitchId.key];
      if (!pitchId) {
        return null;
      }
      return Entities.BaseballResultsProcessor.getRelatedResult(pitchId, this.resultsv1) || null;
    }

    return result;
  }

  /**
   *
   * @param {Number} id
   * @returns {(null | FormattedResult)}
   */
  getHitForMatchingResult(id) {
    let result = this.MatchingResult(id);

    if (!result) {
      return null;
    }

    if (Session.isHitType(result)) {
      return result;
    }

    const hitId = get(result,[Enums.ResultData.HitId.key]);
    if (!hitId) {
      return null;
    }

    return Entities.BaseballResultsProcessor.getRelatedResult(hitId, this.resultsv1) || null;
  }

  /**
   * @type {Boolean}
   */
  get awayTeamLoaded() {
    return this.awayLineup && !!this.awayLineup.team;
  }

  /**
   * @type {Boolean}
   */
  get hasTeamsLoaded() {
    if (this.awayLineup && !this.awayLineup.team) {
      return false;
    }

    return this.homeTeamLoaded;
  }

  /**
   * @type {Boolean}
   */
  get hasPlayersLoaded() {
    if (this.hasTeamsLoaded) {
      if (this.awayLineup?.team) {
        return this.homeLineup?.team?.fetchedPlayers && this.awayLineup?.team?.fetchedPlayers;
      }

      if (this.homeLineup.team) {
        return !!this.homeLineup.team.fetchedPlayers;
      }
    }

    return false;
  }

  /**
   * @type {Boolean}
   */
  get mightHaveRadarData() {
    return this.features.R;
  }

  /**
   * @type {Boolean}
   */
  get mightHaveNewScoringData() {
    return !!(this.features.S && this.parameters?.SessionSchemaRef);
  }

  /**
   * @type {Boolean}
   */
  get mightHaveTwoTeams() {
    return scoringSessionTypes.includes(this.type);
  }

  /**
   * V1 Fallbacks
   **/
  get CreateDate() {
    return this.createDate;
  }

  get SessionID() {
    return this.id;
  }

  get SessionType() {
    return this.sessionType;
  }

  get DisplayName() {
    return this.displayName;
  }

  get hasExtendedTagging() {
    return Classificators.SessionType.gameOrScrimmage(this.sessionType)
      && !Classificators.ScoringType.noExtendedTagging(this);
  }

  get sessionWithoutExtendedTagging() {
    return Classificators.Session.sessionWithoutExtendedTagging(this);
  }
}

export default Session;
