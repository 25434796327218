const FilterStores = Object.freeze({
  GLOBAL: 'global',
  RELEASE_POINT: 'release_point',
  PITCHING_SUMMARY: 'pitching_summary',
  PITCHING_POST_GAME: 'pitching_post_game',
  HITTING_POST_GAME: 'hitting_post_game',
  RELEASE_EXTENSION: 'release_extension',
  PITCH_TRACKING: 'pitch_tracking',
  PITCH_MOVEMENT: 'pitch_movement',
  LOCATION_CHART: 'location_chart',
  DATA_TABLE: 'data_table',
  HITTING_SUMMARY: 'hitting_summary',
  STRIKEZONE_CHART: 'strikezone_chart',
  HIT_SPRAY: 'hit_spray',
  HIT_TRACKING: 'hit_tracking',
});

export default FilterStores;
